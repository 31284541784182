import React, { useState, useEffect } from "react";
import { urls } from "../../utils/api";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MagnifyingGlassIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowDownTrayIcon,
  FunnelIcon,
} from "@heroicons/react/20/solid";

export const UrlList = () => {
  const { isAdmin } = useAuth();
  const [urlList, setUrlList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    direction: "desc",
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      const { data } = await urls.getUserUrls();
      setUrlList(data);
    } catch (error) {
      toast.error("Failed to fetch URLs");
    } finally {
      setLoading(false);
    }
  };

  // Filter data
  const filteredData = urlList.filter((url) => {
    const matchesSearch =
      url.originalUrl.toLowerCase().includes(searchTerm.toLowerCase()) ||
      url.shortCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (isAdmin &&
        url.user?.email?.toLowerCase().includes(searchTerm.toLowerCase()));

    if (!matchesSearch) return false;

    if (dateRange.startDate && dateRange.endDate) {
      const urlDate = new Date(url.createdAt);
      return urlDate >= dateRange.startDate && urlDate <= dateRange.endDate;
    }

    return true;
  });

  // Sort data
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.field === "clicks") {
      return sortConfig.direction === "asc"
        ? a.clicks - b.clicks
        : b.clicks - a.clicks;
    }
    if (sortConfig.field === "createdAt") {
      return sortConfig.direction === "asc"
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    }
    return 0;
  });

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const handleSort = (field) => {
    setSortConfig((current) => ({
      field,
      direction:
        current.field === field && current.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedUrls(currentItems.map((url) => url._id));
    } else {
      setSelectedUrls([]);
    }
  };

  const handleSelectUrl = (urlId) => {
    setSelectedUrls((prev) => {
      if (prev.includes(urlId)) {
        return prev.filter((id) => id !== urlId);
      }
      return [...prev, urlId];
    });
  };

  const handleDelete = async (urlId) => {
    if (!window.confirm("Are you sure you want to delete this URL?")) {
      return;
    }

    try {
      await urls.deleteUrl(urlId);
      toast.success("URL deleted successfully");
      fetchUrls();
    } catch (error) {
      toast.error("Failed to delete URL");
    }
  };

  const handleBulkDelete = async () => {
    if (selectedUrls.length === 0) {
      toast.error("Please select URLs to delete");
      return;
    }

    if (
      !window.confirm(
        `Are you sure you want to delete ${selectedUrls.length} selected URLs?`
      )
    ) {
      return;
    }

    try {
      await urls.bulkDelete(selectedUrls);
      toast.success(`Successfully deleted ${selectedUrls.length} URLs`);
      setSelectedUrls([]);
      setSelectAll(false);
      fetchUrls();
    } catch (error) {
      toast.error("Failed to delete URLs");
    }
  };

  const getCsvData = () => {
    return sortedData.map((url) => ({
      "Original URL": url.originalUrl,
      "Short URL": `${url.domain}/r/${url.shortCode}`,
      Clicks: url.clicks,
      "Created At": new Date(url.createdAt).toLocaleDateString(),
      ...(isAdmin ? { User: url.user?.email } : {}),
    }));
  };

  // Mobile card component
  const MobileUrlCard = ({ url }) => (
    <div className="bg-white p-4 rounded-lg shadow mb-4 border border-gray-200">
      <div className="flex items-center justify-between mb-2">
        <input
          type="checkbox"
          checked={selectedUrls.includes(url._id)}
          onChange={() => handleSelectUrl(url._id)}
          className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
        />
        <button
          onClick={() => handleDelete(url._id)}
          className="text-red-600 hover:text-red-900 text-sm"
        >
          Delete
        </button>
      </div>

      <div className="space-y-2">
        <div>
          <label className="text-xs text-gray-500">Original URL</label>
          <div className="text-sm break-all">{url.originalUrl}</div>
        </div>

        <div>
          <label className="text-xs text-gray-500">Short URL</label>
          <div className="text-sm">
            <a
              href={`${url.domain.name}/r/${url.shortCode}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${url.domain.name}/r/${url.shortCode}`}
            </a>
          </div>
        </div>

        <div className="flex justify-between">
          <div>
            <label className="text-xs text-gray-500">Clicks</label>
            <div className="text-sm">{url.clicks}</div>
          </div>

          <div>
            <label className="text-xs text-gray-500">Created</label>
            <div className="text-sm">
              {new Date(url.createdAt).toLocaleDateString()}
            </div>
          </div>

          {isAdmin && (
            <div>
              <label className="text-xs text-gray-500">User</label>
              <div className="text-sm">{url.user?.email || "N/A"}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const SortableHeader = ({ field, children }) => (
    <th
      onClick={() => handleSort(field)}
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
    >
      <div className="flex items-center space-x-1">
        <span>{children}</span>
        {sortConfig.field === field &&
          (sortConfig.direction === "asc" ? (
            <ChevronUpIcon className="w-4 h-4" />
          ) : (
            <ChevronDownIcon className="w-4 h-4" />
          ))}
      </div>
    </th>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">Loading...</div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Advanced Filters */}
      <div className="mb-4 space-y-4">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          {/* Search */}
          <div className="relative w-full sm:w-64">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search URLs..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Actions */}
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
            >
              <FunnelIcon className="h-5 w-5" />
              Filters
            </button>

            <CSVLink
              data={getCsvData()}
              filename="url-list.csv"
              className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              <ArrowDownTrayIcon className="h-5 w-5" />
              Export CSV
            </CSVLink>

            {selectedUrls.length > 0 && (
              <button
                onClick={handleBulkDelete}
                className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete Selected ({selectedUrls.length})
              </button>
            )}
          </div>
        </div>

        {/* Advanced Filters Panel */}
        {showFilters && (
          <div className="p-4 bg-gray-50 rounded-lg space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <DatePicker
                  selected={dateRange.startDate}
                  onChange={(date) =>
                    setDateRange((prev) => ({ ...prev, startDate: date }))
                  }
                  className="w-full p-2 border rounded"
                  placeholderText="Select start date"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <DatePicker
                  selected={dateRange.endDate}
                  onChange={(date) =>
                    setDateRange((prev) => ({ ...prev, endDate: date }))
                  }
                  className="w-full p-2 border rounded"
                  placeholderText="Select end date"
                  minDate={dateRange.startDate}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Items per page
                </label>
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  className="w-full p-2 border rounded"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  setDateRange({ startDate: null, endDate: null });
                  setItemsPerPage(10);
                  setSearchTerm("");
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Reset Filters
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Desktop Table View */}
      <div className="hidden md:block bg-white shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="w-4 px-6 py-3">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                />
              </th>
              <SortableHeader field="originalUrl">Original URL</SortableHeader>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Short URL
              </th>
              {isAdmin && (
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
              )}
              <SortableHeader field="clicks">Clicks</SortableHeader>
              <SortableHeader field="createdAt">Created At</SortableHeader>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {urlList.map((url) => (
              <tr
                key={url._id}
                className={
                  selectedUrls.includes(url._id) ? "bg-blue-50" : undefined
                }
              >
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedUrls.includes(url._id)}
                    onChange={() => handleSelectUrl(url._id)}
                    className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div className="truncate max-w-xs">{url.originalUrl}</div>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                  <a
                    href={`${url.domain.name}/r/${url.shortCode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${url.domain.name}/r/${url.shortCode}`}
                  </a>
                </td>

                {isAdmin && (
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {url.user?.email || "N/A"}
                  </td>
                )}
                <td className="px-6 py-4 text-sm text-gray-500">
                  {url.clicks}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {new Date(url.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                  <button
                    onClick={() => handleDelete(url._id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile View */}
      <div className="md:hidden space-y-4">
        {currentItems.map((url) => (
          <MobileUrlCard key={url._id} url={url} />
        ))}
      </div>

      {/* Pagination - Responsive */}
      <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-700 text-center sm:text-left">
          Showing {indexOfFirstItem + 1} to{" "}
          {Math.min(indexOfLastItem, sortedData.length)} of {sortedData.length}{" "}
          results
        </div>

        <div className="flex flex-col sm:flex-row items-center gap-4">
          {/* Desktop Pagination */}
          <div className="hidden sm:flex items-center gap-2">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded bg-white border text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              First
            </button>
            {Array.from({ length: totalPages }, (_, i) => i + 1)
              .filter(
                (page) =>
                  Math.abs(page - currentPage) <= 2 ||
                  page === 1 ||
                  page === totalPages
              )
              .map((page) => (
                <React.Fragment key={page}>
                  {page !== 1 &&
                    page - 1 > currentPage - 2 &&
                    page !== totalPages && (
                      <span className="px-3 py-1">...</span>
                    )}
                  <button
                    onClick={() => setCurrentPage(page)}
                    className={`px-3 py-1 rounded ${
                      currentPage === page
                        ? "bg-primary text-white"
                        : "bg-white text-gray-700 hover:bg-gray-50"
                    }`}
                  >
                    {page}
                  </button>
                </React.Fragment>
              ))}
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded bg-white border text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Last
            </button>
          </div>

          {/* Mobile Pagination */}
          <div className="flex sm:hidden justify-center gap-2 w-full">
            <button
              onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 rounded bg-white border text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="px-4 py-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage(Math.min(totalPages, currentPage + 1))
              }
              disabled={currentPage === totalPages}
              className="px-4 py-2 rounded bg-white border text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Empty State */}
      {filteredData.length === 0 && !loading && (
        <div className="text-center py-8 text-gray-500">
          {searchTerm ? "No URLs match your search" : "No URLs created yet"}
        </div>
      )}
    </div>
  );
};
