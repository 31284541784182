// src/components/url/UrlGenerator.jsx
import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { urls, admin } from "../../utils/api";
import { useAuth } from "../../context/AuthContext";
import { useSettings } from "../../context/SettingsContext";

export const UrlGenerator = () => {
  const { isAdmin, user } = useAuth();
  const { settings } = useSettings();
  const [availableDomains, setAvailableDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    originalUrl: "",
    domain: "",
    count: 1,
  });
  const [generatedUrls, setGeneratedUrls] = useState([]);

  const fetchDomains = useCallback(async () => {
    try {
      console.log("Current User:", user);
      console.log("Is Admin:", isAdmin);

      const { data } = await admin.getActive();
      console.log("Fetched Domains Full Response:", data);

      // Tambahkan pengecekan tambahan
      if (!Array.isArray(data)) {
        console.error("Unexpected data format:", data);
        toast.error("Invalid domain data received");
        return;
      }

      if (data.length === 0) {
        toast.error("No active domains available");
        return;
      }

      setAvailableDomains(
        data.map((domain) => ({
          id: domain._id,
          name: domain.name,
        }))
      );
      if (data.length > 0) {
        // Pastikan mengambil name dengan benar
        const firstDomainName = data[0].name || data[0].domain;
        setFormData((prev) => ({ ...prev, domain: firstDomainName }));
      }
    } catch (error) {
      console.error("Domain Fetch Error FULL:", error);

      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);

        // Tambahkan penanganan spesifik untuk error 403
        if (error.response.status === 403) {
          toast.error("You do not have permission to fetch domains");
        } else {
          toast.error(error.response.data.message || "Failed to fetch domains");
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response from server");
      } else {
        console.error("Error setting up request:", error.message);
        toast.error("Error setting up domain request");
      }
    }
  }, [isAdmin, user]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await urls.generate(formData);
      setGeneratedUrls((prevUrls) => [...prevUrls, ...response.data]);
      setFormData((prev) => ({ ...prev, originalUrl: "" }));
      toast.success("URL generated successfully!");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to generate URL";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8">Generate Short URL</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Original URL Input */}
        <div>
          <label
            htmlFor="originalUrl"
            className="block text-sm font-medium text-gray-700"
          >
            Original URL
          </label>
          <div className="mt-1">
            <input
              type="url"
              id="originalUrl"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              value={formData.originalUrl}
              onChange={(e) =>
                setFormData({ ...formData, originalUrl: e.target.value })
              }
              placeholder="https://example.com"
            />
          </div>
        </div>

        {/* Domain Selection */}
        <div>
          <label
            htmlFor="domain"
            className="block text-sm font-medium text-gray-700"
          >
            Select Domain
          </label>
          <select
            id="domain"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
            value={formData.domain}
            onChange={(e) => {
              console.log("Selected domain:", e.target.value);
              setFormData({ ...formData, domain: e.target.value });
            }}
          >
            {availableDomains.map((domain) => {
              console.log("Mapping domain:", domain);
              return (
                <option key={domain.id || domain.name} value={domain.name}>
                  {domain.name}
                </option>
              );
            })}
          </select>
        </div>

        {/* Count Input (Only for Admin) */}
        {isAdmin && settings?.adminBulkGenerate && (
          <div>
            <label
              htmlFor="count"
              className="block text-sm font-medium text-gray-700"
            >
              Number of URLs
            </label>
            <input
              type="number"
              id="count"
              min="1"
              max={settings?.maxBulkGenerate || 50}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              value={formData.count}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  count: parseInt(e.target.value) || 1,
                })
              }
            />
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
        >
          {loading ? "Generating..." : "Generate URL"}
        </button>
      </form>
      {generatedUrls.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">Generated URLs</h2>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Original URL
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Short URL
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {generatedUrls.map((url) => (
                  <tr key={url._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {url.originalUrl}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                      <a
                        href={url.shortUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url.shortUrl}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
