import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import toast from "react-hot-toast";
import { UrlList } from "../url/UrlList";
import { DomainManagement } from "./DomainManagement";
import { Settings } from "./Settings";

export const AdminDashboard = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("pending");
  const [showFullTabs, setShowFullTabs] = useState(false);

  const tabs = [
    { id: "pending", label: `Pending`, count: pendingUsers.length },
    { id: "users", label: `Users`, count: users.length },
    { id: "urls", label: "URLs" },
    { id: "domains", label: "Domains" },
    { id: "settings", label: "Settings" },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [pendingRes, usersRes] = await Promise.all([
        api.get("/admin/users/pending"),
        api.get("/admin/users"),
      ]);
      setPendingUsers(pendingRes.data);
      setUsers(usersRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (userId) => {
    try {
      const response = await api.post(`/admin/users/${userId}/approve`);
      if (response.status === 200) {
        toast.success("User approved");
        fetchData();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error approving user:", error);
      toast.error("Failed to approve user");
    }
  };

  const handleReject = async (userId) => {
    try {
      const response = await api.post(`/admin/users/${userId}/reject`);
      if (response.status === 200) {
        toast.success("User rejected");
        fetchData();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error rejecting user:", error);
      toast.error("Failed to reject user");
    }
  };

  const handleDelete = async (userId) => {
    if (!window.confirm("Are you sure? This will delete all user's URLs."))
      return;

    try {
      const response = await api.delete(`/admin/users/${userId}`);
      if (response.status === 200) {
        toast.success("User deleted");
        fetchData();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user");
    }
  };

  // Mobile user card component
  const UserCard = ({ user, isPending }) => (
    <div className="bg-white p-4 rounded-lg shadow mb-4 border border-gray-200">
      <div className="space-y-3">
        <div className="flex justify-between items-start">
          <div>
            <div className="font-medium text-gray-900">{user.email}</div>
            <div className="text-sm text-gray-500">
              {new Date(user.createdAt).toLocaleDateString()}
            </div>
          </div>
          <span
            className={`px-2 py-1 text-xs font-semibold rounded-full ${
              user.status === "approved"
                ? "bg-green-100 text-green-800"
                : user.status === "rejected"
                ? "bg-red-100 text-red-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            {user.status}
          </span>
        </div>

        <div className="flex flex-wrap gap-2 pt-2 border-t">
          {isPending && (
            <>
              <button
                onClick={() => handleApprove(user._id)}
                className="text-green-600 hover:text-green-900 text-sm"
              >
                Approve
              </button>
              <button
                onClick={() => handleReject(user._id)}
                className="text-red-600 hover:text-red-900 text-sm"
              >
                Reject
              </button>
            </>
          )}
          <button
            onClick={() => handleDelete(user._id)}
            className="text-red-600 hover:text-red-900 text-sm"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (tab) {
      case "pending":
        return renderUsers(pendingUsers, true);
      case "users":
        return renderUsers(users, false);
      case "urls":
        return <UrlList isAdminView />;
      case "domains":
        return <DomainManagement />;
      case "settings":
        return <Settings />;
      default:
        return null;
    }
  };

  const renderUsers = (data, isPending) => (
    <>
      {/* Desktop View */}
      <div className="hidden md:block overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Registered
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.length === 0 ? (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                  No data available
                </td>
              </tr>
            ) : (
              data.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {user.email}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.status === "approved"
                          ? "bg-green-100 text-green-800"
                          : user.status === "rejected"
                          ? "bg-red-100 text-red-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {user.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div className="flex space-x-2">
                      {isPending && (
                        <>
                          <button
                            onClick={() => handleApprove(user._id)}
                            className="text-green-600 hover:text-green-900"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => handleReject(user._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Reject
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => handleDelete(user._id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        {data.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            No data available
          </div>
        ) : (
          data.map((user) => (
            <UserCard key={user._id} user={user} isPending={isPending} />
          ))
        )}
      </div>
    </>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">Loading...</div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Responsive Tab Navigation */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex justify-between items-center md:hidden mb-4">
          <div className="font-medium">
            {tabs.find((t) => t.id === tab)?.label}
          </div>
          <button
            onClick={() => setShowFullTabs(!showFullTabs)}
            className="text-gray-500"
          >
            Menu
          </button>
        </div>

        <nav
          className={`${
            showFullTabs ? "flex" : "hidden"
          } md:flex flex-col md:flex-row md:space-x-8`}
        >
          {tabs.map((t) => (
            <button
              key={t.id}
              onClick={() => {
                setTab(t.id);
                setShowFullTabs(false);
              }}
              className={`${
                tab === t.id
                  ? "border-primary text-primary"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } py-2 md:py-4 px-1 border-l-4 md:border-l-0 md:border-b-2 font-medium text-sm flex justify-between items-center`}
            >
              <span>{t.label}</span>
              {t.count !== undefined && (
                <span className="ml-2 bg-gray-100 text-gray-600 px-2 py-1 rounded-full text-xs">
                  {t.count}
                </span>
              )}
            </button>
          ))}
        </nav>
      </div>
      {renderContent()}
    </div>
  );
};
