// components/admin/Settings.jsx
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSettings } from "../../context/SettingsContext";

export const Settings = () => {
  const { settings: globalSettings, updateSettings } = useSettings();
  const [settings, setSettings] = useState(globalSettings);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await updateSettings(settings);
    if (success) {
      toast.success("Settings updated successfully");
    } else {
      toast.error("Failed to update settings");
    }
  };

  return (
    <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-6">System Settings</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Maximum Links per User
          </label>
          <input
            type="number"
            min="1"
            className="w-full px-3 py-2 border rounded-md"
            value={settings.maxLinksPerUser}
            onChange={(e) =>
              setSettings({
                ...settings,
                maxLinksPerUser: parseInt(e.target.value),
              })
            }
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Maximum Bulk Generate Count
          </label>
          <input
            type="number"
            min="1"
            className="w-full px-3 py-2 border rounded-md"
            value={settings.maxBulkGenerate}
            onChange={(e) =>
              setSettings({
                ...settings,
                maxBulkGenerate: parseInt(e.target.value),
              })
            }
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="adminBulkGenerate"
            className="h-4 w-4 text-primary border-gray-300 rounded"
            checked={settings.adminBulkGenerate}
            onChange={(e) =>
              setSettings({
                ...settings,
                adminBulkGenerate: e.target.checked,
              })
            }
          />
          <label
            htmlFor="adminBulkGenerate"
            className="ml-2 block text-sm text-gray-900"
          >
            Enable bulk generation for admin
          </label>
        </div>

        <button
          type="submit"
          className="w-full bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark"
        >
          Save Settings
        </button>
      </form>
    </div>
  );
};
