// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { SettingsProvider } from "./context/SettingsContext";
import { Navbar } from "./components/layout/Navbar";
import { Footer } from "./components/layout/Footer";
import { Login } from "./components/auth/Login";
import { Register } from "./components/auth/Register";
import { UrlGenerator } from "./components/url/UrlGenerator";
import { UrlList } from "./components/url/UrlList";
import LandingPage from "./components/pages/LandingPage";
// footer
import { PrivacyPolicy } from "./components/pages/PrivacyPolicy";
import { TermsOfService } from "./components/pages/Terms";
import { ContactUs } from "./components/pages/ContactUs";
import { CookiePolicy } from "./components/pages/CookiePolicy";
import { Disclaimer } from "./components/pages/Disclaimer";

import { AdminRoute } from "./components/routes/AdminRoute";
import { AdminDashboard } from "./components/admin/AdminDashboard";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
};

// Layout component yang akan membungkus semua halaman
const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8">{children}</main>
      <Footer />
    </div>
  );
};

const App = () => {
  const { user } = useAuth();

  return (
    <>
      <Toaster position="top-right" />
      <Layout>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={user ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route
            path="/register"
            element={user ? <Navigate to="/dashboard" /> : <Register />}
          />
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <div className="space-y-8">
                  <UrlGenerator />
                  <UrlList />
                </div>
              </PrivateRoute>
            }
          />

          {/* Admin Route */}
          <Route
            path="/admin/*"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          />

          {/* 404 Route */}
          <Route
            path="*"
            element={
              <div className="text-center py-12">
                <h2 className="text-2xl font-bold text-gray-900">
                  404 - Page Not Found
                </h2>
                <p className="mt-2 text-gray-600">
                  The page you are looking for doesn't exist.
                </p>
              </div>
            }
          />
        </Routes>
      </Layout>
    </>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <AuthProvider>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppWrapper;
