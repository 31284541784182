// src/context/SettingsContext.jsx
import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../utils/api";

const SettingsContext = createContext();

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    maxLinksPerUser: 100,
    maxBulkGenerate: 50,
    adminBulkGenerate: true,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const { data } = await api.get("/admin/settings");
      setSettings(data);
    } catch (error) {
      console.error("Failed to fetch settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (newSettings) => {
    try {
      const { data } = await api.put("/admin/settings", newSettings);
      setSettings(data);
      return true;
    } catch (error) {
      console.error("Failed to update settings:", error);
      return false;
    }
  };

  return (
    <SettingsContext.Provider value={{ settings, loading, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
