// src/components/pages/Disclaimer.jsx
import React from "react";

export const Disclaimer = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-lg p-6 sm:p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Disclaimer</h1>

          <div className="prose prose-blue max-w-none">
            <p className="text-gray-600 mb-6">
              Last updated: {new Date().toLocaleDateString()}
            </p>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Website Disclaimer
              </h2>
              <p className="text-gray-600">
                The information provided by ShortLink on our website is for
                general informational purposes only. All information is provided
                in good faith, however we make no representation or warranty of
                any kind, express or implied, regarding the accuracy, adequacy,
                validity, reliability, availability, or completeness of any
                information on our website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                External Links Disclaimer
              </h2>
              <p className="text-gray-600">
                Our website may contain links to other websites. We have no
                control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or
                services. We strongly advise you to read the terms and
                conditions and privacy policies of any third-party sites that
                you visit.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Liability
              </h2>
              <p className="text-gray-600">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will limit or exclude our liability for death or personal injury
                resulting from negligence.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
