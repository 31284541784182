// src/utils/api.js
import axios from "axios";

const instance = axios.create({
  baseURL: "https://runlink.biz.id/api",
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  return config;
});

export const auth = {
  login: (credentials) => instance.post("/auth/login", credentials),
  register: (credentials) => instance.post("/auth/register", credentials),
};

export const urls = {
  generate: (data) => instance.post("/urls/generate", data),
  getUserUrls: () => instance.get("/urls/my-urls"),
  downloadCsv: () =>
    instance.get("/urls/download-csv", { responseType: "blob" }),
  deleteUrl: (id) => instance.delete(`/urls/${id}`),
  bulkDelete: (urlIds) => instance.post("/urls/bulk-delete", { urlIds }),
  getPendingUsers: () => instance.get("/users/pending"),
};

export const admin = {
  getAll: () => instance.get("/admin/domains"),
  getActive: () => instance.get("/admin/domains/active"),
  add: (name) => instance.post("/admin/domains", { name }),
  toggle: (id) => instance.patch(`/admin/domains/${id}/toggle`),
  delete: (id) => instance.delete(`/admin/domains/${id}`),
  getSettings: () => instance.get("/admin/settings"),
  updateSettings: (settings) => instance.put("/admin/settings", settings),
};

export default instance;
