import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { admin } from "../../utils/api";

export const DomainManagement = () => {
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = async () => {
    try {
      const { data } = await admin.getAll();
      setDomains(data);
    } catch (error) {
      toast.error("Failed to fetch domains");
    } finally {
      setLoading(false);
    }
  };

  const handleAddDomain = async (e) => {
    e.preventDefault();
    try {
      const { data } = await admin.add(newDomain);
      setDomains([data, ...domains]);
      setNewDomain("");
      toast.success("Domain added successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add domain");
    }
  };

  const handleToggleStatus = async (id) => {
    try {
      const { data } = await admin.toggle(id);
      setDomains(domains.map((domain) => (domain._id === id ? data : domain)));
      toast.success("Domain status updated");
    } catch (error) {
      toast.error("Failed to update domain status");
    }
  };

  const handleDelete = async (id) => {
    if (
      !window.confirm("Are you sure? This will not delete any existing URLs.")
    ) {
      return;
    }

    try {
      await admin.delete(id);
      setDomains(domains.filter((domain) => domain._id !== id));
      toast.success("Domain deleted successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete domain");
    }
  };

  // Komponen untuk tampilan mobile
  const DomainCard = ({ domain }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mb-4">
      <div className="flex flex-col space-y-3">
        <div className="flex justify-between items-start">
          <div className="break-all font-medium text-gray-900">
            {domain.name}
          </div>
          <span
            className={`px-2 py-1 text-xs font-semibold rounded-full ${
              domain.isActive
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {domain.isActive ? "Active" : "Inactive"}
          </span>
        </div>

        <div className="text-sm text-gray-500">
          Added: {new Date(domain.createdAt).toLocaleDateString()}
        </div>

        <div className="flex justify-end space-x-3 pt-2 border-t">
          <button
            onClick={() => handleToggleStatus(domain._id)}
            className="text-sm text-indigo-600 hover:text-indigo-900"
          >
            {domain.isActive ? "Deactivate" : "Activate"}
          </button>
          <button
            onClick={() => handleDelete(domain._id)}
            className="text-sm text-red-600 hover:text-red-900"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        Loading...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
      {/* Add Domain Form */}
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow mb-6">
        <h2 className="text-xl font-bold mb-4">Add New Domain</h2>
        <form
          onSubmit={handleAddDomain}
          className="flex flex-col sm:flex-row gap-4"
        >
          <input
            type="text"
            value={newDomain}
            onChange={(e) => setNewDomain(e.target.value)}
            placeholder="example.com"
            className="flex-1 px-3 py-2 border rounded-md"
            required
          />
          <button
            type="submit"
            className="w-full sm:w-auto bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark"
          >
            Add Domain
          </button>
        </form>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Domain Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Added On
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {domains.map((domain) => (
              <tr key={domain._id}>
                <td className="px-6 py-4 text-sm text-gray-900 break-all">
                  {domain.name}
                </td>
                <td className="px-6 py-4">
                  <span
                    className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      domain.isActive
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {domain.isActive ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {new Date(domain.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 text-right text-sm font-medium">
                  <button
                    onClick={() => handleToggleStatus(domain._id)}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    {domain.isActive ? "Deactivate" : "Activate"}
                  </button>
                  <button
                    onClick={() => handleDelete(domain._id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile View */}
      <div className="sm:hidden space-y-4">
        {domains.map((domain) => (
          <DomainCard key={domain._id} domain={domain} />
        ))}
      </div>

      {/* Empty State */}
      {domains.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No domains added yet
        </div>
      )}
    </div>
  );
};
