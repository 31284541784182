// src/components/pages/CookiePolicy.jsx
import React from "react";

export const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-lg p-6 sm:p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            Cookie Policy
          </h1>

          <div className="prose prose-blue max-w-none">
            <p className="text-gray-600 mb-6">
              Last updated: {new Date().toLocaleDateString()}
            </p>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                What Are Cookies
              </h2>
              <p className="text-gray-600">
                Cookies are small pieces of text sent by your web browser by a
                website you visit. A cookie file is stored in your web browser
                and allows the Service or a third-party to recognize you and
                make your next visit easier and more useful to you.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                How We Use Cookies
              </h2>
              <p className="text-gray-600 mb-4">
                We use cookies for the following purposes:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>
                  Authentication - We use cookies to identify you when you visit
                  our website.
                </li>
                <li>
                  Security - We use cookies to help identify and prevent
                  security risks.
                </li>
                <li>
                  Performance - We use cookies to understand and analyze the
                  performance of our services.
                </li>
                <li>
                  Analytics - We use cookies to understand how visitors interact
                  with our website.
                </li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Your Choices Regarding Cookies
              </h2>
              <p className="text-gray-600">
                If you'd like to delete cookies or instruct your web browser to
                delete or refuse cookies, please visit the help pages of your
                web browser. Please note, however, that if you delete cookies or
                refuse to accept them, you might not be able to use all of the
                features we offer.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
