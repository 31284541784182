// components/layout/Navbar.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import api from "../../utils/api";

export const Navbar = () => {
  const { user, logout, isAdmin } = useAuth();
  const [pendingUsers, setPendingUsers] = useState(0);

  useEffect(() => {
    if (isAdmin) {
      fetchPendingUsers();
    }
  }, [isAdmin]);

  const fetchPendingUsers = async () => {
    try {
      const { data } = await api.get("/admin/users/pending");
      setPendingUsers(data.length);
    } catch (error) {
      console.error("Failed to fetch pending users");
    }
  };

  return (
    <nav className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/" className="flex items-center">
              <span className="text-xl font-bold text-primary">ShortLink</span>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {user && (
              <Link
                to="/dashboard"
                className="text-gray-700 hover:text-primary"
              >
                Dashboard
              </Link>
            )}
            {isAdmin && (
              <Link
                to="/admin"
                className="text-gray-700 hover:text-primary flex items-center"
              >
                Admin
                {pendingUsers > 0 && (
                  <span className="ml-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                    {pendingUsers}
                  </span>
                )}
              </Link>
            )}
            {user ? (
              <button
                onClick={logout}
                className="text-gray-700 hover:text-primary"
              >
                Logout
              </button>
            ) : (
              <>
                <Link to="/login" className="text-gray-700 hover:text-primary">
                  Login
                </Link>
                <Link
                  to="/register"
                  className="text-gray-700 hover:text-primary"
                >
                  Register
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
