import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export const LandingPage = () => {
  const [longUrl, setLongUrl] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleShortUrlSubmit = (e) => {
    e.preventDefault();
    if (!user) {
      navigate("/login", {
        state: {
          message: "Please login to create short links",
          redirectUrl: longUrl,
        },
      });
      return;
    }
    console.log("Generate short URL:", longUrl);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex flex-col">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-8 md:py-16 flex-grow flex items-center justify-center">
        <div className="text-center max-w-2xl w-full">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900 mb-4 md:mb-6 leading-tight">
            Simplify Your Links,{" "}
            <span className="block md:inline">Expand Your Reach</span>
          </h1>

          <p className="text-lg md:text-xl text-gray-600 mb-6 md:mb-8 px-4">
            Transform long URLs into concise, shareable links. Track performance
            and analytics effortlessly.
          </p>

          {/* Shortlink Form - Responsive */}
          <form
            onSubmit={handleShortUrlSubmit}
            className="bg-white shadow-lg rounded-lg p-4 md:p-6 space-y-3 md:space-y-0 md:flex md:items-center md:space-x-2 mb-8"
          >
            <input
              type="url"
              value={longUrl}
              onChange={(e) => setLongUrl(e.target.value)}
              placeholder="Paste your long URL here"
              required
              className="w-full md:flex-grow px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="w-full md:w-auto bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold 
                       hover:bg-blue-700 transition duration-300"
            >
              {user ? "Shorten" : "Login to Shorten"}
            </button>
          </form>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-white py-12 md:py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-12">
            {/* Simple & Fast */}
            <div className="text-center p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 md:w-12 md:h-12 mx-auto text-blue-600 mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                />
              </svg>
              <h3 className="text-lg md:text-xl font-bold mb-2">
                Simple & Fast
              </h3>
              <p className="text-gray-600">Create short links in seconds</p>
            </div>

            {/* Analytics */}
            <div className="text-center p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 md:w-12 md:h-12 mx-auto text-blue-600 mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                />
              </svg>
              <h3 className="text-lg md:text-xl font-bold mb-2">
                Full Analytics
              </h3>
              <p className="text-gray-600">Track link performance</p>
            </div>

            {/* Security */}
            <div className="text-center p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 md:w-12 md:h-12 mx-auto text-blue-600 mb-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.607-7.852-4.13Z"
                />
              </svg>
              <h3 className="text-lg md:text-xl font-bold mb-2">
                Guaranteed Security
              </h3>
              <p className="text-gray-600">Protect your links</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-100 py-6 px-4 text-center">
        <p className="text-gray-600">
          © {new Date().getFullYear()} ShortLink. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
