import React from "react";

export const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-sm rounded-lg p-6 sm:p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            Terms of Service
          </h1>

          <div className="prose prose-blue max-w-none">
            <p className="text-gray-600 mb-6">
              Last updated: {new Date().toLocaleDateString()}
            </p>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                1. Acceptance of Terms
              </h2>
              <p className="text-gray-600">
                By accessing and using our URL shortening service, you agree to
                be bound by these Terms of Service and all applicable laws and
                regulations. If you do not agree with any of these terms, you
                are prohibited from using this service.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                2. Acceptable Use
              </h2>
              <p className="text-gray-600 mb-4">
                You agree not to use our service for:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Unlawful or fraudulent purposes</li>
                <li>Distribution of malware or harmful content</li>
                <li>Spamming or harassment</li>
                <li>Violation of intellectual property rights</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                3. Service Availability
              </h2>
              <p className="text-gray-600">
                We strive to provide uninterrupted service but cannot guarantee
                that our service will be available at all times. We reserve the
                right to modify or discontinue the service at any time without
                notice.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                4. Account Terms
              </h2>
              <p className="text-gray-600 mb-4">
                To use our service, you must:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                <li>Provide accurate registration information</li>
                <li>Maintain the security of your account</li>
                <li>
                  Accept responsibility for all activities under your account
                </li>
                <li>Notify us of any unauthorized use</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                5. Limitation of Liability
              </h2>
              <p className="text-gray-600">
                We shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages resulting from your use or
                inability to use our service.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
